.Data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
}

.DataIframe {
  border: 0;
  width: 100%;
  height: 600px;
}

.DataText {
  max-width: 100%;
}

.Actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
